<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">

        <!-- STEP 1 -->
        <div class="ibox-content" v-if="etape == 1">
          <h2>Etape 1 : Création de l'offre</h2>

          <div class="form-group">
            <select name="" id="" class="form-control"  v-model="v$.offre.type.id.$model" :class="{ 'form-error': v$.offre.type.id?.$errors.length }">
              <option v-for="typeOffre of availableTypesOffre" v-bind:key="typeOffre.id" :value="typeOffre.id">{{ typeOffre.libelle }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Titre :</label>
            <input v-model="v$.offre.titre.$model"  class="form-control" :class="{ 'form-error': v$.offre.titre.$errors.length }"/>
          </div>
          <div class="form-group">
            <label for="">Description :</label>
            <ckeditor :editor="editor" :config="editorConfig" v-model="v$.offre.description.$model" :class="{ 'form-error': v$.offre.description.$errors.length }"></ckeditor>
          </div>
          <div>
            <label for="" :class="{ 'form-error': v$.offre.etablissement.id.$errors.length }">Etablissement</label>
            <div>
              <button class="btn btn-default" v-for="etablissement in etablissements"
                :key="etablissement.id"
                :class="{'btn-primary': etablissement.id == offre.etablissement?.id}"
                @click="selectEtablissement(etablissement.id)">
                  {{ etablissement.titre }}
              </button>
            </div>
          </div>
          <hr>
          <div>
            <label for="">Intervenants</label>
            <div>
            <button class="btn btn-default" v-for="intervenant in intervenants"
                :key="intervenant.id"
                :class="{'btn-primary': isIntervenantInOffre(intervenant)}"
                @click="selectIntervenant(intervenant)">
                  {{ intervenant.prenom }} {{ intervenant.nom }}
              </button>
            </div>
            <div v-for="intervenant of offre.intervenants" :key="intervenant.id">
              {{ intervenant.prenom }} {{ intervenant.nom }}
            </div>
            <!-- si plusieurs intervenants possibles : choix de l'intervenant, sinon rappelle de l'intervenant -->
          </div>
          <div class="row">
            <div class="col-md-12">
              <button v-on:click="validateFirstStep()" class="btn btn-secondary pull-right">Valider</button>
            </div>
          </div>
        </div>

        <!-- STEP 2 -->
        <div class="ibox-content" v-if="etape == 2">
          <h2>Etape 2 : Configuration de l'expérience</h2>

          <div v-if="offre.type.has_produit">
            <h2>Produits</h2>
            <div class="row">
              <div class="col-md-12">
                <label for="">Combien de bouteille souhaitez vous associer à votre expérience ?</label>
                <select name="" id="" v-model="nbBouteille">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="6">6</option>
                </select>

              </div>
              <div class="col-md-6">
                <ProductSelector @add-product="addProduct"/>
              </div>
              <div class="col-md-6">
                <div class="ibox-content">
                  <h3 :class="{ 'form-error' : nbBouteille != nbTotalProduct}">Produits selectionnés pour l'offre ({{ nbTotalProduct }}/ {{ nbBouteille }}): </h3>
                  <div v-for="produit in offre.produits" :key="produit.id">
                    <img :src="produit.produit.image.full_path + '?h=200'" alt="">
                    <h4>{{ produit.produit.titre }}</h4>
                    <span>{{ produit.produit.prix }} €</span>
                    <p>Quantité : {{ produit.quantite }}</p>
                    <button class="btn btn-primary" v-on:click="removeProduct(produit)">Supprimer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  Ajout Vidéo :
          - cuisine guidée
          - dégustation interactive -->
          <div v-if="offre.type.id == 1 || offre.type.id == 2 ">
            <h2>Vidéo de l'expérience</h2>
            <VimeoUploader v-model="offre.video"
                :uploadurl="uploadVideoUrl"
                :videoid="offre?.video_experience?.fichier"
                @add-video="addVideoExperience"
                @delete-video="removeVideoExperience" />
          </div>

          <div v-if="offre.type.has_limit_participant">
            <h2>Minimum/Maximum de participant</h2>
            <div class="form-group">
            <label for="">Min : </label>
            <input type="number" v-model="offre.nb_participant_min" class="form-control">
            </div>
            <div class="form-group">
            <label for="">Max : </label>
            <input type="number" v-model="offre.nb_participant_max" class="form-control">
            </div>
          </div>
          <div v-if="offre.type.has_hebergement">
            <h2>Synchronisation des informations Vinipedia</h2>
            <p>Votre identifiant d'établissement vinipedia (domaine) : {{ offre.etablissement.mappavini_domaine_id }}</p>
            <button @click="viniSync()" class="btn btn-default">vinisync</button>
            
          </div>

          <!-- Création Recette :
          - La cuisine guidée
          - Les Chefs en direct

          Création Menu :
          - Les menus privés

          Crétation GPX
          - les parcours commentés -->
          <div class="row">
            <div class="col-md-12">
              <button v-on:click="validateSecondStep()" class="btn btn-secondary pull-right">Valider</button>
              <button v-on:click="back()" class="btn btn-warning pull-right">Back</button>
            </div>
          </div>
        </div>

        <!-- STEP 3 MEDIAS -->
        <div class="ibox-content"  v-if="etape == 3">
          <h2>Etape 3 : Médias</h2>
          <div class="row">
            <div class="col-md-6">
              <h3>Video</h3>
              <VimeoUploader v-model="offre.video"
                :uploadurl="uploadVideoUrl"
                :videoid="offre?.video?.fichier"
                @add-video="addVideo"
                @delete-video="removeVideo" />
            </div>
            <div class="col-md-6">
              <h3>Images</h3>
              <div v-for="image in offre.gallery" :key="image.id">
                <img :src="image.full_path" width="200" alt="">
                <button class="btn  btn-warning" @click="removeDocument(image)">X</button>
                <button class="btn" :class="{ 'btn-primary': image.id == offre.image.id, 'btn-default': image.id != offre.image.id}" @click="setDefaultImage(image)">Image Principale</button>
              </div>
              <FileUploader :uploadurl="uploadImageUrl" @add-document="addDocument" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <button v-on:click="postOffre(true)" class="btn btn-secondary pull-right">Valider</button>
              <button v-on:click="back()" class="btn btn-warning pull-right">Back</button>
            </div>
          </div>
        </div>

        <!-- STEP 4 -->
        <div class="ibox-content" v-if="etape == 4">
          <h2>Etape 4 : Calendrier</h2>
          <!-- Si séjour insolite :
          > gestion calendrier ical

          Si
          - vigneron en direct
          > Vendeur : Choix de dates + créneaux uniques
          - chef en direct
          > Vendeur : Choix de dates + créneaux uniques
          - master class
          > Vendeur : Choix de dates + créneaux uniques
          - sommelier en direct
          > Vendeur : Choix de dates + créneaux uniques
          - rencontre en cave
          > Vendeur : Choix de dates + créneaux uniques + UX qui permet de gérer un ajout de date en masse
          - visite guidée
          > Vendeur : Choix de dates + créneaux uniques + UX qui permet de gérer un ajout de date en masse -->
          <!-- <dateOffreHelper v-model="offre.dates" /> -->
            <!-- <div v-for="date of offre.dates" :key="date.id">
              {{ date.date }} <button v-on:click="removeDate()" class="btn btn-default">X</button>
            </div>

            <v-date-picker v-model='newDate' locale="fr" mode="dateTime" :minute-increment="5" is24hr>
                  <template v-slot="{ inputValue, inputEvents }">
                      <input
                          class="bg-white border px-2 py-1 rounded"
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Select date"
                      />
                  </template>
            </v-date-picker>
             <button v-on:click="addDate()" class="btn btn-default">Add date</button> -->
            <div class="form-group">
             <label for="">Durée</label>
             <select name="" id="" class="form-control" v-model="offre.duree" >
               <option value="15">15 minutes</option>
               <option value="30">30 minutes</option>
               <option value="60">1h</option>
               <option value="120">2h</option>
               <option value="180">3h</option>
               <option value="240">4h</option>
               <option value="300">5h</option>
             </select>            
            </div>
            
            <DateSelector v-if="offreSource.id"
                :offre="offreSource"
                :height="600"
                @save="setDates"
                @delete="removeDate"
            >
            </DateSelector>

          <div class="row">
            <div class="col-md-12">
              <button v-on:click="postOffre(true)" class="btn btn-secondary pull-right">Valider</button>
              <button v-on:click="back()" class="btn btn-warning pull-right">Back</button>
            </div>
          </div>
        </div>

        <!-- STEP 5 / PRIX -->
        <div class="ibox-content"  v-if="etape == 5">
          <h2>Etape 5 : Prix</h2>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Prix experience</label>
                <input type="text" v-model="offre.prix_experience" class="form-control">
              </div>
              <div class="form-group" v-if="offre.type.has_produit">
                <label for="">Prix produits</label>
                <input type="text" v-model="offre.prix_produit" class="form-control">
              </div>
              <div>
              <div v-if="offre.type.has_declinaison">
                <h3>Declinaisons de prix de l'experience</h3>
                <div class="alert alert-info">
                    Vous pouvez ajouter ici une déclinaison de prix (par exemple, un prix pour les -18 ans).
                </div>
                <div v-for="declinaison in offre.declinaisons" :key="declinaison.id">
                  <span><strong>{{ declinaison.libelle }}</strong> -
                  {{ declinaison.prix }} €</span>
                  <button class="btn btn-primary" v-on:click="removeDeclinaison(declinaison)">Supprimer</button>
              </div>
              <DeclinaisonSelector @add-declinaison="addDeclinaison"/>
              </div>

              </div>
            </div>
            <div class="col-md-6">
              <h1>TOTAL : {{ prixTotal }} € HT</h1>
              <p>Commission TRINCH ({{ offre.type.taux_commission }} %) : {{ commissionTrinch }} € HT</p>
              <h2>TOTAL CLIENT : {{ prixTotalTtc }} € TTC </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button v-on:click="postOffre(true)" class="btn btn-secondary pull-right">Valider</button>
              <button v-on:click="back()" class="btn btn-warning pull-right">Back</button>
            </div>
          </div>
        </div>

        <!-- STEP 6 -->
        <div class="ibox-content" v-if="etape == 6">
          <h2>Etape 6 : Capsule {{ offre.capsules.length }}</h2>
          <div class="row" v-if="!offre.capsules || offre.capsules.length == 0">
            <div class="col-md-6">

              <div class="form-group">
                <label for="">Titre</label>
                <input type="text" class="form-control" v-model="capsule.titre">
              </div>

              <div class="form-group">
                <label for="">Message</label>
                <textarea class="form-control" v-model="capsule.description"></textarea>

              </div>

            </div>
            <div class="col-md-6">

              <h3>Video</h3>
              <VimeoUploader v-model="offre.video" :uploadurl="uploadVideoUrl" :videoid="offre?.video?.fichier" />

              <hr>
              <h3>Image</h3>
                <img v-if="capsule.image" :src="capsule.image.full_path" width="200" alt="">
                <FileUploader :uploadurl="uploadImageUrl" @add-document="addImageCapsule" />
            </div>

            <div class="col-md-12">
              <button v-on:click="postCapsule()" class="btn btn-secondary pull-right">Terminer</button>
            </div>
          </div>
          <div class="row">
            Vous avez déjà créer une capsule pour cette offre.
            <router-link :to="'/offre/' + this.offre.uuid ">Terminer</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <img src="@/assets/loading.svg" alt="">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ProductSelector from "@/components/ProductSelector";
import DeclinaisonSelector from "@/components/DeclinaisonSelector";
import VimeoUploader from "@/components/VimeoUploader";
import FileUploader from "@/components/FileUploader";
import DateSelector from "@/components/DateSelector";
import Offre from "@/core/models/Offre"
import Capsule from "@/core/models/Capsule"
import DateOffre from "@/core/models/DateOffre"
import { required, minLength } from '@vuelidate/validators'
import userService from '@/service/user'
import useVuelidate from '@vuelidate/core'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';


export default {
  name: "OffreCreator",
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      offre: {},
      files: [],
      intervenants: [],
      selected: {},
      newDate: new Date(),
      etape: 1,
      loading: false,
      userService: userService,
      capsule: new Capsule(),
      editor: ClassicEditor,
      editorConfig : {
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
      },
      nbBouteille: 6
    };
  },
  validations() {
      return {
        offre: {
          titre: {
              required,
              minLength: minLength(4)
          },
          description: {
              required,
              minLength: minLength(4)
          },
          type: {
            id : {
              required
            }
          },
          etablissement: {
            id : {
              required
            }
          },
        },

      }
  },
  created() {    
    this.offre = null;
    //this.v$.$reset()
    this.fetchData();

  },
  mounted() {    
    //this.v$.$reset()
    this.v$.$reset()
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
    user(){
      if(!this.offre.intervenants.length) this.offre.intervenants.push(this.user);
    }
  },
  methods: {
    validateFirstStep(){
      this.v$.$touch()
      if( !this.v$.$invalid){
        this.postOffre(true)
      }
    },
    validateSecondStep(){
      
      if(this.offre.type.has_produit){           
        if(this.nbTotalProduct == this.nbBouteille){
          this.postOffre(true)
        }
      }else{
        this.postOffre(true)
      }
      
      //
    },
    fetchData() {
      this.offre = new Offre();
      this.$store.dispatch('getEtablissements');
      if(this.$route.params.uuid){
        this.loading = true;
        axios
          .get(
            process.env.VUE_APP_API + "/producteur/offre/" +
              this.$route.params.uuid,
            {}
          )
          .then((resp) => {
            this.offre = resp.data;            
            //this.v$.$reset()


            this.loading = false;
            this.v$.$reset()
            this.v$.$touch()
          })
          .catch((error) => {
            console.log("ERROR", error);
          });
      }else{
        // récupération des etablissements
        if(this.user.id && !this.offre.intervenants.length) this.offre.intervenants.push(this.user);
        if(this.etablissements.length == 1) this.offre.etablissement = this.etablissements[0];

      }
      this.loadIntervenants();
      // this.$store.dispatch("getEtablissement", this.$route.params.uuid);
    },
    loadIntervenants() {
      axios
          .get(
            process.env.VUE_APP_API + "/producteur/intervenants",
            {}
          )
          .then((resp) => {
            this.intervenants = resp.data;
          })
          .catch((error) => {
            console.log("ERROR", error);
          });
    },
    postOffre(goNext = false) {
      const loading = this.$loading({
          lock: true,
        });
      axios
        .post(
          process.env.VUE_APP_API + "/producteur/offre",
          this.offre
        )
        .then((resp) => {
          this.offre = resp.data;
          loading.close();
          if(this.etape == 5){
            this.capsule.offre = this.offre
            this.capsule.titre = this.offre.titre
          }
          this.$notify({
              title: 'Enregistrée !',
              message: 'Votre offre a bien été enregistrée',
              type: 'success'
            });
          this.v$.$touch()
          if(goNext) this.etape++
        })
        .catch((error) => {
          console.log("ERROR", error);
          loading.close();
          this.$notify({
              title: 'Erreur',
              message: 'Erreur lors de l\'enregistrement de l\'offre',
              type: 'error'
            });
        });
    },
    postCapsule() {
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_API + "/producteur/capsule",
          this.capsule
        )
        .then(() => {
            // REDIRECT TO DASHBOARD            
            this.$router.push('/offre/' + this.offre.uuid)
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    back() {
      this.etape--
    },
    setDefaultImage(image){
      this.offre.image = image
      this.postOffre()
    },
    addDeclinaison(declinaison) {
      if(!this.offre.declinaisons){
        this.offre.declinaisons = []
      }
      this.offre.declinaisons.push(declinaison)
      this.postOffre()
    },
    removeDeclinaison(declinaison) {
      this.offre.declinaisons = this.offre.declinaisons.filter( (obj) => {
        return obj.id != declinaison.id
      })
    },
    addProduct(OffreProduit) {
      if(this.nbTotalProduct < 6){
        this.offre.produits.push(OffreProduit)
        this.postOffre()
      }else{

        this.$notify({
              title: 'Erreur',
              message: 'Max 6',
              type: 'error'
            });
      }
    },
    removeProduct(produit) {
      this.offre.produits = this.offre.produits.filter( (obj) => {
        return obj.id != produit.id
      })
    },
    setDates(dates) {      
      let dateOffres = this.offre.dates;
      dates.forEach( d => {
        let dateOffre =  new DateOffre();
        dateOffre.date = moment(d).format();
        dateOffres.push(dateOffre)
      })
      this.offre.dates = dateOffres
      this.postOffre()
    },
    addDate() {
      let dateOffre =  new DateOffre();
      dateOffre.date = moment(this.newDate).format();
      this.offre.dates.push(dateOffre)
      this.postOffre()
    },
    removeDate(date) {
      this.offre.dates = this.offre.dates.filter(function( obj ) {
          return obj.id !== date.id;
      });
      this.postOffre()
    },
    addDocument(doc){
      this.offre.gallery.push(doc)
      this.postOffre()
    },
    removeDocument(doc){
      this.offre.gallery = this.offre.gallery.filter(function( obj ) {
          return obj.id !== doc.id;
      });
      this.postOffre()
    },
    addVideo(doc){
      this.offre.video = doc;
      this.postOffre()
    },
    removeVideo(){
      this.offre.video = null;
      this.postOffre()
    },
    addVideoExperience(doc){
      this.offre.video_experience = doc;
      this.postOffre()
    },
    removeVideoExperience(){
      this.offre.video_experience = null;
      this.postOffre()
    },
    selectEtablissement(etablissement_id){

      this.offre.etablissement = {
        id: etablissement_id
      }
      this.v$.$touch()

    },
    selectIntervenant(intervenant){
      if(this.isIntervenantInOffre(intervenant)){
        this.offre.intervenants = this.offre.intervenants.filter(i => {
          return i.id != intervenant.id
        })
      }else{
        this.offre.intervenants.push(intervenant)
      }
    },
    isIntervenantInOffre(intervenant){
      let is_in = false;
      this.offre.intervenants.forEach( i => {
        if(i.id == intervenant.id){
          is_in = true

        }
      })
      return is_in

    },
    addImageCapsule(doc) {      
      this.capsule.image = doc
    },
    viniSync(){
      
      axios
        .post(
          process.env.VUE_APP_API + "/producteur/offre/vinisync/"+ this.offre.uuid
        )
        .then((resp) => {
          this.offre = resp.data;
          //loading.close();
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    }
  },
  computed: {
    nbTotalProduct() {
      let nb = 0;      
      for(let product of this.offre.produits){        
        nb += product.quantite
      }      
      return nb
    },
    typesOffre() {
        return this.$store.getters.getTypesOffre
    },
    availableTypesOffre() {
      return this.typesOffre.filter( t => {
        return userService.hasRole(t.roles)
      })
    },
    uploadVideoUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/video'
    },
    uploadImageUrl() {
      return process.env.VUE_APP_API + '/producteur/offre/upload/image/'+ this.offre.uuid
    },
    prixProduits(){
        let prix = 0;
        for(let product in this.offre.produits){
          prix += product.prix
        }
        return prix;
    },
    prixTotal(){
        if(this.offre.prix_experience){
          if(this.offre.type.has_produit) {
            return parseInt(this.offre.prix_experience) + parseInt(this.offre.prix_produit)
          }else{
            return parseInt(this.offre.prix_experience);
          }
        }
        return 0;
    },
    prixTotalTtc(){
        return (this.prixTotal + this.prixTotal*(20/100)).toFixed(2)
    },
    commissionTrinch() {
      return this.prixTotal*(this.offre.type.taux_commission/100);
    },
    etablissements () {
      return this.$store.state.etablissements.etablissements
    },
    user () {
      return this.$store.state.user.profile
    },
    offreSource() {
      return {
        id: this.offre.id,
        titre: this.offre.titre,
        duree: this.offre.duree,
        dates: this.offre.dates.map(d => {
          return {
            id: d.id,
            date: d.date
          }
        })
      }
    }
  },
  components: {
    ProductSelector,
    VimeoUploader,
    DeclinaisonSelector,
    FileUploader,
    ckeditor: CKEditor.component,
    DateSelector
    //dateOffreHelper
  },
};
</script>

<style scoped>
.w-4{
  width: 3px;
}
.loading{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .5);
  z-index: 5000;
}
</style>