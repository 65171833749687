<template>
    <div class="ibox-content" v-if="produit">
        <div class="row">
            <div class="col-md-4">
                <img :src="produit.image.full_path + '?h=200'" alt="" class="max100">
            </div>
            <div class="col-md-8">
                <h3>{{ produit.titre }}</h3>
                <span>{{ produit.prix }} €</span>
                <p>STOCK : {{ produit.stock }}</p>                  
                <div class="form-group">
                    <input type="text" placeholder="Quantite" class="form-control" v-model="quantite" >
                </div>
                
                <button v-on:click="addProduct()" class="btn btn-primary">Ajouter à l'experience</button>   
            </div>
        </div>
    </div>
</template>

<script>
import { required, minValue, numeric } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {
    name: 'NavBar',
    emits: ['add-product'],
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            quantite: 1,
        };
    },
    validations() {
        return {            
            quantite: { 
                required,
                numeric,
                minValue: minValue(1)
            },
        }
  },
  props: [
      'produit'
  ],
  methods: {
      addProduct(){
                
        this.v$.$touch()
        
        if( !this.v$.$invalid){
            console.log("LAUCNCH ADD PRODUIT")
            this.$emit('add-product', {
                produit: this.produit,
                quantite: this.quantite
            })
        }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
