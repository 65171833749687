import Type from './Type'

export default class Offre {
    id;
    uuid;
    titre;
    description;
    type = new Type();
    gallery = [];
    produits = [];
    intervenants = [];
    etablissement = [];
    declinaisons = [];
    dates = [];
    video;
    prix_experience = 0;
    prix_produit = 0;
}