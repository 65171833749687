<template>
  <div class="hello">    
    <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Description</label>
            <input type="text" class="form-control" v-model="libelle">
          </div>
          <div class="form-group">
            <label for="">Prix</label>
            <input type="text" class="form-control"  v-model="prix">
          </div>
          <button class="btn btn-primary" v-on:click="addDeclinaison()">Ajouter une déclinaison de prix</button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeclinaisonSelector',
  data() {
    return {
      libelle: "",
      prix: 0,
    };
  },
  methods: {
    addDeclinaison(){
      this.$emit('add-declinaison', {
        prix: this.prix,
        libelle: this.libelle,
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
