<template>
  <div class="ibox-content">
    <h3>Vos produits déjà enregistrés sur Trinch</h3>
    <div>
        <div v-for="produit in produits" :key="produit.id">                    
            <ProductSelectorUnit :produit="produit" @add-product="addProduct"/>     
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProductSelectorUnit from "@/components/ProductSelectorUnit";

export default {
  name: 'ProductSelector',
  emits: ['add-product'],
  data() {
    return {
      produits: {},
    };
  },
  components: {
    ProductSelectorUnit
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    addProduct(OffreProduit){
        this.$emit('add-product', OffreProduit)      
    },
    fetchData() {
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/produits",
          {}
        )
        .then((resp) => {
          this.produits = resp.data;
        })
        .catch(() => {
          console.log("ERROR");
        });
    },
  },
}
</script>